/**
 * Created by yantingdiwu on 2021/9/23.
 */
const _video = {
    replayFun(){
        const {url, cover, time} = $(this).data(),
            title = $(this).parents('li').find('.title').text(),
            desc = $(this).parents('li').find('.desc').text(),
            html = `<div class='video-modal'>
                        <div class="video" id="video"></div>
                        <div class="video-info">
                            <p class="title">${title}</p>
                            <p class="time">发布时间： ${time}</p>
                            <div class="desc">${desc}</div>
                        </div>
                    </div>`

        layer.open({
            type: 1,
            title: false,
            area: ['800px', 'auto'],
            shadeClose: true,
            closeBtn: 0,
            content: html,
            success: function () {
                new QPlayer({
                    url: url,
                    container: document.getElementById("video"),
                    autoplay: false,
                    noControls: false,
                    poster: cover
                });
            }
        });


    }
}

$(() => {
    $(document)
        .on('click', '._showVideo', _video.replayFun)
})